/*
 * Core
 * */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import InputGlobal from "shared/components/Form/InputGlobal";

/*
 * Assets
 * */
import { ReactComponent as EyeIcon } from "assets/shared/img/eye.svg";
import { ReactComponent as EyeIconHide } from "assets/shared/img/eye-hide.svg";

export const SignInInfo = ({
  data,
  setData,
  emailError,
  setEmailError,
  setPasswordError,
  passwordError,
  repeatPasswordError,
  setRepeatPasswordError,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const { t } = useTranslation("auth");

  /**
   * Handle change Email
   * @param {object} event
   */
  const handleChangeEmail = (event) => {
    setEmailError(null);
    setData({ ...data, email: event?.target?.value });
  };

  /**
   * Handler for change Password
   * @param {object} event
   */
  const handleSetPassword = (event) => {
    setPasswordError(null);
    setData({ ...data, password: event?.target?.value });
  };

  /**
   * Handler for change repeat Password
   * @param {object} event
   */
  const handleRepeatPassword = (event) => {
    setRepeatPasswordError(null);
    setData({ ...data, repeat_password: event?.target?.value });
  };

  /*
   * Toggle for show Password on click icon eye
   * */
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /*
   * Toggle for show repeat Password on click icon eye
   * */
  const toggleShowPasswordRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
  };

  return (
    <div className="sign__in-wrap">
      <span className="label_global">{t("labelEmail")}</span>
      <InputGlobal
        className={"register__email"}
        handleChange={(e) => handleChangeEmail(e)}
        name="email"
        value={data.email}
        error={!!emailError}
        errorText={emailError}
        placeholder={t("placeholderEmail")}
      />
      <span className="label_global">{t("passwordLabel")}</span>
      <div className="password__wrap">
        <InputGlobal
          className={"register__password"}
          handleChange={(val) => handleSetPassword(val)}
          name="change-password"
          type={showPassword ? "text" : "password"}
          value={data.password}
          error={!!passwordError}
          errorText={passwordError}
          placeholder={t("passwordPlaceholder")}
          rightIconSrc={showPassword ? <EyeIconHide /> : <EyeIcon />}
          handleClickRightIcon={toggleShowPassword}
          passwordShowIcon={showPassword}
          // autoComplete={"new-password"}
        />
        <InputGlobal
          className={"register__password"}
          handleChange={(val) => handleRepeatPassword(val)}
          name="repeat-password"
          type={showPasswordRepeat ? "text" : "password"}
          value={data.repeat_password}
          error={!!repeatPasswordError}
          errorText={repeatPasswordError}
          placeholder={t("repeatPassword")}
          rightIconSrc={showPasswordRepeat ? <EyeIconHide /> : <EyeIcon />}
          handleClickRightIcon={toggleShowPasswordRepeat}
          passwordShowIcon={showPasswordRepeat}
        />
      </div>
      {import.meta.env.VITE_APP_REG_ADDITIONAL_INFO && (
        <div className="confirm__text">
          {t("common:registerFormText2", {
            brandName: import.meta.env.VITE_APP_MULTI_SITE_BRAND_NAME,
          })}
        </div>
      )}
    </div>
  );
};

SignInInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  emailError: PropTypes.any,
  setEmailError: PropTypes.func,
  setPasswordError: PropTypes.func,
  passwordError: PropTypes.any,
  repeatPasswordError: PropTypes.any,
  setRepeatPasswordError: PropTypes.func,
};
