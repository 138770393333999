/*
 * Core
 * */
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { CheckBox } from "shared/components/Form/CheckBox";
import InputGlobal from "shared/components/Form/InputGlobal";
import RadioButton from "shared/components/Form/RadioButton";
import Select from "shared/components/Form/Select";

import { GENDER_FEMALE, GENDER_MALE } from "shared/constants";
import { OPTION_DISABLED, OPTION_ENABLED } from "shared/constants/options";

import { simplyClick } from "shared/helpers/analytics";
import {
  getDate,
  getMonths,
  getYearByKey,
  getYears,
} from "shared/helpers/date";

export const PersonalInfo = ({
  data,
  setData,
  isModal,
  errorGender,
  errorDate,
  setErrorDate,
  errorName,
  setErrorName,
  termsError,
  setTermsError,
  errorYear,
  setErrorYear,
  errorMonth,
  setErrorMonth,
  errorDay,
  setErrorDay,
  checkBox,
  setCheckBox,
}) => {
  const { t } = useTranslation("auth");

  /**
   * Handler for change Gender
   * @param {number} value gender user
   */
  const handleChangeGender = (value) => {
    setData({ ...data, gender: +value });
  };

  /**
   * Handler for change Name
   * @param {object} event
   */
  const handleChangeName = (event) => {
    setErrorName(null);
    setData({ ...data, first_name: event?.target?.value });
  };

  /**
   * Handler for change Month
   * @param {number} value birthday user
   */
  const handleChangeMonth = (value) => {
    setErrorDate(null);
    setErrorMonth(null);
    setData({ ...data, month: +value });
  };

  /**
   * Handler for change Day
   * @param {number} value birthday user
   */
  const handleChangeDay = (value) => {
    setErrorDay(null);
    setErrorDate(null);
    setData({ ...data, day: +value });
  };

  /**
   * Handler for change Year
   * @param {number} value birthday user
   */
  const handleChangeYear = (value) => {
    setErrorYear(null);
    setErrorDate(null);
    setData({ ...data, year: getYearByKey(+value) });
  };

  /*
   * handler for change terms
   * */
  const handleCheckBox = () => {
    setTermsError(false);
    setCheckBox(!checkBox);
  };

  return (
    <div className="personal__info-wrap">
      <span className="label_global">{t("IamLabel")}</span>
      <div className="gender_wrap">
        <div className="gender_wrap-item">
          <RadioButton
            name={isModal ? "genderModal" : "gender"}
            label="Man"
            id={isModal ? "radio1Modal" : "radio1"}
            value={GENDER_MALE}
            cb={handleChangeGender}
            isSelected={data.gender === GENDER_MALE}
            isForm={true}
            customClass={"mirror"}
          />
          {errorGender && <p className="error">{errorGender}</p>}
        </div>

        <div className="gender_wrap-item">
          <RadioButton
            name={isModal ? "genderModal" : "gender"}
            label="Woman"
            id={isModal ? "radio2Modal" : "radio2"}
            value={GENDER_FEMALE}
            cb={handleChangeGender}
            isSelected={data.gender === GENDER_FEMALE}
            isForm={true}
            customClass={"mirror"}
          />
        </div>
      </div>
      <span className="label_global">{t("nameIsLabel")}</span>
      <InputGlobal
        className={"register__form"}
        handleChange={(e) => handleChangeName(e)}
        name="name"
        value={data.first_name}
        error={!!errorName}
        errorText={errorName}
        placeholder={t("nameIsPlaceholder")}
        onFocus={() => {
          simplyClick("cl_reg_action");
        }}
      />
      <span className="label_global">{t("birthdayLabel")}</span>
      <div className="birthday__wrap">
        <Select
          className={errorMonth ? "error__register" : ""}
          customClassList="date_item"
          values={getMonths()}
          value={getMonths()[data.month] ?? ""}
          handleChange={handleChangeMonth}
          name="month"
          placeholder={t("monthPlaceholder")}
          error={!!errorMonth}
        />
        <Select
          className={errorDay ? "error__register" : ""}
          customClassList="date_item"
          values={getDate()}
          value={getDate()[data.day] ?? ""}
          handleChange={handleChangeDay}
          name="day"
          placeholder={t("dayPlaceholder")}
          error={!!errorDay}
        />
        <Select
          className={errorYear ? "error__register" : ""}
          customClassList="date_item"
          values={getYears()}
          value={data.year ?? ""}
          handleChange={handleChangeYear}
          name="year"
          placeholder={t("yearPlaceholder")}
          error={!!errorYear}
        />
        {errorDate && <p className="error date">{errorDate}</p>}
      </div>

      <div className="terms__wrap">
        <CheckBox
          handleChange={handleCheckBox}
          name="is_terms"
          value={checkBox ? OPTION_ENABLED : OPTION_DISABLED}
          checkedValue={OPTION_ENABLED}
          isError={termsError}
        />
        <div className="terms_text">
          <Trans
            t={t}
            i18nKey="termsOfUse.link1"
            components={[
              <a target="_blank" href="/terms-of-use/" key={0}>
                <i aria-hidden="true"></i>
              </a>,
            ]}
          />
          <Trans
            t={t}
            i18nKey="termsOfUse.link2"
            components={[
              <a target="_blank" href="/privacy-policy/" key={0}>
                <i aria-hidden="true"></i>
              </a>,
            ]}
          />
        </div>
      </div>
      {termsError && <p className="error">{termsError}</p>}
    </div>
  );
};

PersonalInfo.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  isModal: PropTypes.bool,
  errorGender: PropTypes.any,
  errorDate: PropTypes.any,
  setErrorDate: PropTypes.func,
  errorName: PropTypes.any,
  setErrorName: PropTypes.func,
  termsError: PropTypes.any,
  setTermsError: PropTypes.func,
  errorYear: PropTypes.any,
  setErrorYear: PropTypes.func,
  errorMonth: PropTypes.any,
  setErrorMonth: PropTypes.func,
  errorDay: PropTypes.any,
  setErrorDay: PropTypes.func,
  checkBox: PropTypes.bool,
  setCheckBox: PropTypes.func,
};
