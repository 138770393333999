/**
 Core
 */
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { AboutYou } from "shared/components/Auth/AboutYou";
import ButtonGoogle from "shared/components/Auth/ButtonGoogle";

import {
  CONST_REG_TYPE_GOOGLE,
  ERROR_STATUS_NOT_CONFIRM,
  ERROR_STATUS_UNPROCESSABLE,
  GENDER_FEMALE,
  GENDER_MALE,
  GOOGLE_API_GENDER_MALE,
} from "shared/constants";

import { registrationFailedEvent } from "shared/helpers/analytics";
import {
  afterRegistrationSuccess,
  prepareRegistrationData,
} from "shared/helpers/auth";
import {
  getBirthdayFromRegDate,
  getBirthdayInFormat,
} from "shared/helpers/date";
import { isFullDataUserValidate, validateAge } from "shared/helpers/validation";

import { registrationUser } from "shared/services/auth";

const registerGoogleContainer = document.getElementById("registration__google");

/**
 * Google sign up component for registration by google account
 * @return {JSX.Element}
 */
const GoogleSignUp = ({
  setLoader,
  handleDateChange,
  setData,
  sendForm,
  setResponseError,
  setEmailError,
}) => {
  const [baseInfo, setBaseInfo] = useState({ finished: false, data: {} });
  const [fullInfo, setFullInfo] = useState({ finished: false, data: {} });
  const [error, setError] = useState({ show: false, text: null });
  const [isShowing, setIsShowing] = useState(false);
  const [dataGoogle, setDataGoogle] = useState({
    google_id: null,
    first_name: null,
    email: null,
    gender: null,
    reg_source_type: CONST_REG_TYPE_GOOGLE,
    birthday: null,
  });

  const { t } = useTranslation("auth");

  useEffect(() => {
    if (!baseInfo.finished || !fullInfo.finished) {
      return;
    }

    setLoader(false);
    mapDataToStorage();
  }, [baseInfo, fullInfo]);

  const handleClickSignUpGoogle = () => {
    if (sendForm) {
      return;
    }
    setBaseInfo({ finished: false, data: {} });
    setFullInfo({ finished: false, data: {} });
    setError({ show: false, text: null });
  };

  /**
   * Show more about you modal
   */
  const showAboutYouFrom = () => {
    setIsShowing(true);
    document.body.style.overflow = "hidden";
    window.scrollTo({
      top: 0,
    });
  };

  /**
   * After google login handler
   *
   * @param {null|boolean} error Error status
   * @param {Object} data Google login data
   */
  const handleGoogleLogin = (error, data) => {
    if (!error && data.access_token) {
      setLoader(true);
      getUserBaseInfo(data.access_token);
      getUserFullInfo(data.access_token);
      return;
    }

    setError({ show: true, text: t("signUoGoogleRespError") });
  };

  /**
   * Get user base info data
   * @param {String} accessToken Google access token
   */
  const getUserBaseInfo = (accessToken) => {
    fetch("https://www.googleapis.com/oauth2/v1/userinfo?alt=json", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data) {
              setBaseInfo({ finished: true, data });
              return;
            }
            setBaseInfo({ finished: true, data: {} });
          })
          .catch(() => setBaseInfo({ finished: true, data: {} }));
      })
      .catch(() => setBaseInfo({ finished: true, data: {} }));
  };

  /**
   * Get user full info data
   * @param {String} accessToken Google access token
   */
  const getUserFullInfo = (accessToken) => {
    fetch(
      "https://people.googleapis.com/v1/people/me?alt=json&personFields=genders,birthdays",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data) {
              const fullData = {
                gender: null,
                birthday: null,
              };

              if (
                data.genders &&
                data.genders.length > 0 &&
                data.genders[0].value
              ) {
                fullData.gender =
                  data.genders[0].value === GOOGLE_API_GENDER_MALE
                    ? GENDER_MALE
                    : GENDER_FEMALE;
              }
              let birthdayData;
              if (data.birthdays && data.birthdays.length > 0) {
                data.birthdays.forEach((itemDate) => {
                  if (
                    itemDate.date.month &&
                    itemDate.date.day &&
                    itemDate.date.year
                  ) {
                    birthdayData = itemDate.date;
                  }
                });
                if (!birthdayData) {
                  return;
                }
                const isValidAge = validateAge(
                  birthdayData.month,
                  birthdayData.day,
                  birthdayData.year
                );

                if (isValidAge) {
                  fullData.birthday = getBirthdayInFormat(
                    birthdayData.year,
                    birthdayData.month,
                    birthdayData.day
                  );
                }
              }

              setFullInfo({ finished: true, data: fullData });
              return;
            }
            setFullInfo({ finished: true, data: {} });
          })
          .catch(() => setFullInfo({ finished: true, data: {} }));
      })
      .catch(() => setFullInfo({ finished: true, data: {} }));
  };

  /**
   * Prepare data and submit form
   */
  const mapDataToStorage = () => {
    const stateObject = {
      google_id: null,
      first_name: null,
      email: null,
      gender: null,
      reg_source_type: CONST_REG_TYPE_GOOGLE,
      birthday: null,
      year: null,
      month: null,
      day: null,
    };

    if (fullInfo?.data?.birthday) {
      const birthdayData = getBirthdayFromRegDate(dataGoogle?.birthday);
      stateObject.year = birthdayData.year;
      stateObject.month = birthdayData.month;
      stateObject.day = birthdayData.day;
    }

    if (!baseInfo.data?.id || !baseInfo.data?.email) {
      setError({ show: true, text: t("signUoGoogleRespError") });
      return;
    }

    stateObject.google_id = baseInfo.data.id;
    stateObject.first_name = baseInfo.data?.name;
    stateObject.email = baseInfo.data?.email;
    stateObject.gender = fullInfo.data?.gender;
    stateObject.birthday = fullInfo.data?.birthday;

    Object.keys(stateObject).forEach((key) => {
      if (
        stateObject[key] === null ||
        typeof stateObject[key] === "undefined"
      ) {
        return;
      }

      const target = {
        target: {
          name: key,
          value: stateObject[key].value,
        },
      };

      if (
        ["day", "month", "year"].indexOf(key) >= 0 &&
        handleDateChange === "function"
      ) {
        handleDateChange(target);
      }
    });

    setDataGoogle({
      ...dataGoogle,
      google_id: baseInfo.data.id,
      first_name: baseInfo.data?.name,
      email: baseInfo.data?.email,
      gender: fullInfo.data?.gender,
      birthday: fullInfo.data?.birthday,
    });

    const { valid } = isFullDataUserValidate(stateObject);
    if (!valid) {
      showAboutYouFrom();
      return;
    }

    setLoader(true);
    setTimeout(() => {
      registrationUser(prepareRegistrationData(stateObject))
        .then((resp) => responseCb(resp))
        .catch((err) => responseErrorCb(err));
    }, 50);
  };

  /**
   * Success request on api
   * @param {object} response success request on api
   */
  const responseCb = (response) => {
    afterRegistrationSuccess(
      response,
      true,
      () => setData({}),
      () => setLoader(false)
    );
  };

  /**
   * Failed request on api
   * @param {object} responseErr failed request on api
   */
  const responseErrorCb = (responseErr) => {
    registrationFailedEvent(true);

    if (responseErr.response.status === ERROR_STATUS_UNPROCESSABLE) {
      setLoader(false);
      const { data } = responseErr.response;
      let errorText = "";

      data.errors.forEach((error) => {
        if (error.name === "email") {
          setEmailError(error.message);
        } else if (error.name !== "email") {
          errorText += error > 0 ? " " + error.message : error.message;
        }
      });

      if (errorText !== "") {
        setResponseError(errorText);
      }
      return;
    } else if (responseErr.response.status === ERROR_STATUS_NOT_CONFIRM) {
      window.location.href = "/moderation/";
      return;
    }
    setResponseError(t("cantRegisterProfileError"));
    setLoader(false);
  };

  return (
    <>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_APP_GOOGLE_AUTH_CLIENT_ID}
      >
        <ButtonGoogle
          className={"button__google" + (error.show ? " error__google" : "")}
          text={"Sign up via Google"}
          id={"sign__up-google"}
          handleClick={handleClickSignUpGoogle}
          handleGoogleLogin={handleGoogleLogin}
          handleBeforeClick={handleClickSignUpGoogle}
          type={"button"}
        />
        {error.show && error.text && (
          <p className="error response">{error.text}</p>
        )}
      </GoogleOAuthProvider>

      {registerGoogleContainer &&
        createPortal(
          <AboutYou
            dataGoogle={dataGoogle}
            active={isShowing}
            setIsShowing={setIsShowing}
          />,
          registerGoogleContainer
        )}
    </>
  );
};

export default GoogleSignUp;
