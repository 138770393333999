/*
 * Core
 * */
import { lazy, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import InputGlobal from "shared/components/Form/InputGlobal";
import { Textarea } from "shared/components/Form/Textarea";
// import Lottie from "lottie-react";
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "shared/components/SpinLoader";

import {
  ERROR_STATUS_UNPROCESSABLE,
  MAX_LENGTH_TEXTAREA,
} from "shared/constants";
import { DESPUTE_TOO_LONG_DESCRIPTION } from "shared/constants/errors";

import { clearForm } from "shared/helpers/auth";
import { validateEmail, validateName } from "shared/helpers/validation";

import { sendContactUs } from "shared/services/auth";

/*
 * Assets
 * */
import successAnimation from "assets/shared/lotties/success-mark.json";

const Lottie = lazy(() => import("lottie-react"));
export const ContactUs = () => {
  const [contactUsData, setContactUsData] = useState({});
  const [errorName, setErrorName] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [successContactUs, setSuccessContactUs] = useState(false);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation("auth");
  const recaptchaRef = useRef(null);

  /*
   * Styles for animation wrap
   * */
  const style = {
    height: 86,
    width: 136,
    marginLeft: "auto",
    marginRight: "auto",
  };

  /*
   * Effect for clean Contact Us Modal
   */
  useEffect(() => {
    clearForm(".close__icon", ".background__modal", () => clearContactUsForm());
  }, []);

  /*
   * Effect for fixed min height success modal
   */
  useEffect(() => {
    const successWrap = document.querySelector(".contact_us-wrap");
    if (successContactUs && successWrap) {
      successWrap.classList.add("fixed");
    } else {
      successWrap.classList.remove("fixed");
    }
  }, [successContactUs]);

  /*
   * Effect for close Contact Us Modal
   */
  let contactUsModal;
  useEffect(() => {
    if (!contactUsModal) {
      contactUsModal = document.querySelector(".contact__us-modal");
    }
  }, []);

  /*
   * Clear Contact Us Form
   */
  const clearContactUsForm = () => {
    recaptchaRef.current?.reset();
    setSuccessContactUs(false);
    setContactUsData({});
    setErrorName(null);
    setErrorEmail(null);
    setCaptchaError(null);
    setErrorDescription(null);
    setResponseError(null);
  };

  /**
   * Close Success Modal
   */
  const handleCloseSuccessModal = () => {
    setSuccessContactUs(false);
    setContactUsData({});
    if (!contactUsModal) {
      contactUsModal = document.querySelector(".contact__us-modal");
    }
    if (contactUsModal) {
      contactUsModal.classList.remove("show");
      document.body.style.overflow = "auto";
    }
  };

  /**
   * Handler for change Name
   * @param {object} event
   */
  const handleChangeName = (event) => {
    setErrorName(null);
    setContactUsData({ ...contactUsData, name: event?.target?.value });
  };

  /**
   * Handle change Email
   * @param {object} event
   */
  const handleChangeEmail = (event) => {
    setErrorEmail(null);
    setContactUsData({ ...contactUsData, email: event?.target?.value });
  };

  /**
   * Handle change description
   * @param {string} descriptionValue
   */
  const handleDescription = (descriptionValue) => {
    setErrorDescription(null);
    setContactUsData({ ...contactUsData, text: descriptionValue });
  };

  /**
   * Handle change Google Captcha
   * @param {string} value recaptcha
   */
  const onCaptchaChange = (value) => {
    setCaptchaError(null);
    setContactUsData({ ...contactUsData, reCaptcha: value });
  };

  /**
   * Handle submit form and request on api
   */
  const handleRequestContactUs = () => {
    let isValidateDataForm = true;
    const resultName = validateName(contactUsData.name);
    if (!resultName.status) {
      setErrorName(resultName.error);
      isValidateDataForm = false;
    }
    const resultEmail = validateEmail(contactUsData.email);
    if (!resultEmail.status) {
      setErrorEmail(resultEmail.error);
      isValidateDataForm = false;
    }
    if (
      !contactUsData.text ||
      contactUsData.text.trim().length === 0 ||
      contactUsData.text.trim() === "undefined"
    ) {
      setErrorDescription(t("emptyError"));
      isValidateDataForm = false;
    } else if (contactUsData.text.length > MAX_LENGTH_TEXTAREA) {
      setErrorDescription(DESPUTE_TOO_LONG_DESCRIPTION);
      isValidateDataForm = false;
    }
    if (!contactUsData.reCaptcha) {
      setCaptchaError(t("captchaError"));
      isValidateDataForm = false;
    }
    if (isValidateDataForm) {
      setLoader(true);
      successCbContactUs(contactUsData, () => {
        setLoader(false);
        setSuccessContactUs(true);
      });
    }
  };

  /**
   * Request Contact Us on api
   * @param {object} dataReset
   * @param {function|null} cbSuccess
   */
  const successCbContactUs = (dataReset, cbSuccess = null) => {
    sendContactUs(dataReset)
      .then(({ data }) => {
        if (data?.success) {
          setLoader(false);
          if (cbSuccess) {
            cbSuccess();
          }
        }
      })
      .catch(({ response }) => {
        setLoader(false);
        recaptchaRef.current?.reset();
        setContactUsData({ ...contactUsData, reCaptcha: "" });
        if (response.status === ERROR_STATUS_UNPROCESSABLE) {
          const { data } = response;
          let errorText = "";
          data.errors.forEach((error) => {
            if (error.name === "name") {
              setErrorName(error.message);
            } else if (error.name === "email") {
              setErrorEmail(error.message);
            } else if (error.name === "text") {
              setErrorDescription(error.message);
            } else if (error.name === "reCaptcha") {
              recaptchaRef.current?.reset();
              setCaptchaError(error.message);
            } else {
              errorText +=
                errorText.length > 0 ? " " + error.message : error.message;
            }
          });
          if (errorText.length !== 0) {
            setResponseError(errorText);
          }
        } else {
          setResponseError(t("common:error.canUpdate"));
        }
      });
  };

  return (
    <>
      {loader && (
        <SpinLoader
          color={LOADER_COLOR_DARK}
          size={LOADER_SIZE_LARGE}
          position={POSITION_ABSOLUTE}
          withBlur={true}
          isFormBlur={true}
        />
      )}
      {successContactUs ? (
        <>
          <div className="contact_us-body-success">
            <Lottie
              loop={false}
              style={style}
              animationData={successAnimation}
              autoplay={true}
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
            />
            <span className="title">{t("successModalTitle")}</span>
            <p className="text">{t("successModalText")}</p>
            <button onClick={handleCloseSuccessModal} className="form__button">
              OK
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="contact_us-body">
            <div className="contact_us-header">
              <span className="title">{t("modalTitle")}</span>
            </div>
            <span className="label_global">{t("labelName")}</span>
            <InputGlobal
              className={"register__form"}
              handleChange={(e) => handleChangeName(e)}
              name="name_contactUs"
              value={contactUsData.name}
              error={!!errorName}
              errorText={errorName}
              placeholder={t("placeholderName")}
            />
            <span className="label_global">Email</span>
            <InputGlobal
              className={"register__email"}
              handleChange={(e) => handleChangeEmail(e)}
              name="email_contactUs"
              value={contactUsData.email}
              error={!!errorEmail}
              errorText={errorEmail}
              placeholder={t("placeholderEmail")}
            />
            <span className="label_global">Message</span>
            <div className="textarea__wrap">
              <Textarea
                name={"description"}
                value={contactUsData.text ?? ""}
                handleChange={handleDescription}
                rows={5}
                textareaId={"description"}
                placeholder={t("messagePlaceholder")}
                customClass={errorDescription ? "error_textarea" : ""}
                error={!!errorDescription}
              />
              {errorDescription && <p className="error">{errorDescription}</p>}
            </div>
            <div className={captchaError ? "recaptcha-error" : ""}>
              <div className="g-recaptcha">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_APP_RECAPTCHA_TOKEN}
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                />
              </div>
              {captchaError && <p className="error captcha">{captchaError}</p>}
            </div>
          </div>
          <div className="contact_us-footer">
            <button onClick={handleRequestContactUs} className="form__button">
              {t("buttonSend")}
            </button>
            {responseError && <p className="error response">{responseError}</p>}
          </div>
        </>
      )}
    </>
  );
};
