import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";

//
import { ContactUs } from "shared/components/Auth/ContactUs";
import LoginForm from "shared/components/Auth/LoginForm";
import RegistrationForm from "shared/components/Auth/RegistrationForm";
import { RestorePass } from "shared/components/Auth/RestorePass";

import "i18n";

import "@brandAssets/scss/main.scss";

if (document.getElementById("login")) {
  ReactDOM.createRoot(document.getElementById("login")).render(<LoginForm />);
}

if (document.getElementById("registration")) {
  ReactDOM.createRoot(document.getElementById("registration")).render(
    <RegistrationForm />
  );
}

// ReactDOM.createRoot(document.getElementById("registration-modal")).render(
//   <RegistrationForm isModal={true} />
// );

if (document.getElementById("restore")) {
  ReactDOM.createRoot(document.getElementById("restore")).render(
    <RestorePass />
  );
}

if (document.getElementById("contact_us")) {
  ReactDOM.createRoot(document.getElementById("contact_us")).render(
    <ContactUs />
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
